<template>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="d-flex flex-column flex-row-fluid wrapper">
        <!--begin::Toolbar-->
        <div class="toolbar d-print-none py-5 py-lg-7">
          <!--begin::Container-->
          <div class="container-fluid d-flex flex-stack flex-wrap align-items-start">
            <!--begin::Page title-->
            <div class="page-title d-flex flex-column me-3">
              <h1 class="h2 text-white">
                {{ state === 'Change Order' ? 'Change Order' : 'New Proposal' }}: {{ scopeType }}
              </h1>
              <p class="fs-6 text-white mb-0" v-if="projectName">{{ projectName }}</p>
            </div>
            <!--end::Page title-->

            <!--begin::Actions-->
            <div class="d-flex pt-4">
              <div class="symbol-group me-9">
                <span
                  v-if="manager"
                  :class="['symbol symbol-35px symbol-circle', { 'me-4': users.length }]"
                  v-tooltip="{
                    content: manager.full_name,
                    delay: { show: 400, hide: 0 },
                    placement: 'bottom',
                  }"
                >
                  <img v-if="manager.avatar" :alt="manager.full_name" :src="manager.avatar" />
                  <span v-else class="symbol-label text-white fw-semi-bold bg-primary">
                    {{ manager.initials }}
                  </span>
                </span>
                <div v-if="users.length" class="symbol-group">
                  <span
                    v-for="user in users"
                    :key="user.id"
                    class="symbol symbol-35px symbol-circle"
                    :title="`${user.attributes.first_name} ${user.attributes.last_name}`"
                    v-tooltip="{
                      content: `${user.attributes.first_name} ${user.attributes.last_name}`,
                      delay: { show: 400, hide: 0 },
                      placement: 'bottom',
                    }"
                  >
                    <img
                      :src="user.attributes.avatar"
                      :alt="`${user.attributes.first_name} ${user.attributes.last_name}`"
                    />
                  </span>
                </div>
              </div>
              <px-btn size="sm" color="success" extended-classes="text-white" @click.native="onExit">Exit</px-btn>
            </div>
            <!--end::Actions-->
          </div>
          <!--end::Container-->
        </div>
        <!--end::Toolbar-->

        <div class="container-fluid pb-10">
          <div class="content flex-row-fluid">
            <div class="card position-relative">
              <div class="card-body px-4">
                <div class="d-flex d-print-none justify-content-between mb-6">
                  <div class="mt-n4 me-4" />
                  <div class="d-flex align-items-center">
                    <span
                      v-if="isCurrent"
                      class="badge badge-primary fw-medium text-uppercase text-ls fs-10 px-3 py-2 me-4"
                    >
                      {{ signedManualy ? 'Signed Manually' : status }}
                    </span>
                    <span v-else class="badge badge-danger fw-medium text-uppercase text-ls fs-10 px-3 py-2 me-4">
                      Old Version
                    </span>
                    <div v-if="historyItems.length" class="min-w-200px me-4">
                      <px-select
                        v-model="historyItemsVal"
                        placeholder="Select a version"
                        :items="historyItems"
                        track-by="id"
                        :text-by="['attributes.number', ' ', '|', ' ', 'v', 'attributes.version']"
                        :minimum-results-for-search="Infinity"
                        select2-id="select2_history_items_ids"
                        @input="() => goToHistory(historyItemsVal)"
                      />
                    </div>
                    <div
                      class="btn btn-icon btn-light btn-active-light-primary me-4"
                      data-fc-fullscreen-toggler
                      @click="toggleFullScreen"
                    >
                      <i class="bi bi-fullscreen" />
                    </div>
                    <div class="position-relative">
                      <div
                        v-if="isContentLoading"
                        class="position-absolute top-0 bottom-0 start-0 end-0 z-4 d-flex align-items-center justify-content-center bg-primary rounded"
                      >
                        <span class="spinner-border spinner-border-sm" />
                      </div>
                      <px-dropdown
                        v-if="isCurrent"
                        title="Actions"
                        btn-classes="btn btn-sm btn-primary dropdown-toggle"
                        :is-icon-hidden="true"
                        :items="[
                          {
                            title: 'Create new Version',
                            disabled: createNewProposalBasedOnCurrentInProcess,
                            inProcess: createNewProposalBasedOnCurrentInProcess,
                            action: () => createNewProposalBasedOnCurrent(itemId),
                          },
                          { title: 'Share', action: openModal },
                          {
                            title: 'Generate PDF',
                            disabled: isPdfInProcess,
                            inProcess: isPdfInProcess,
                            action: () => makeAndDownloadPdf(pdfFile),
                          },
                          {
                            title: 'Edit',
                            disabled: isSentToSign,
                            action: () => createNewProposalBasedOnCurrent(itemId),
                          },
                          {
                            title: isSentToSign ? 'Resend for Sign' : 'Send for Sign',
                            disabled: subTotal <= 0 || signedPdfFile,
                            inProcess: isPdfAndSendForSignInProcess,
                            action: sendForSign,
                          },
                          { title: 'Print', disabled: true, action: printContract },
                          { title: 'Download', disabled: true, action: () => downloadFile(pdfFile) },
                        ]"
                      />

                      <px-dropdown
                        v-else
                        title="Actions"
                        btn-classes="btn btn-sm btn-primary dropdown-toggle"
                        :is-icon-hidden="true"
                        :items="[
                          {
                            title: 'Create new Version',
                            disabled: createNewProposalBasedOnCurrentInProcess,
                            inProcess: createNewProposalBasedOnCurrentInProcess,
                            action: () => createNewProposalBasedOnCurrent(itemId),
                          },
                          { title: 'PDF', action: () => downloadFile(pdfFile) },
                          { title: 'Print', disabled: true, action: printContract },
                          { title: 'Download', disabled: true, action: () => downloadFile(pdfFile) },
                        ]"
                      />
                    </div>
                  </div>
                </div>

                <div class="position-relative">
                  <px-loader v-if="isContentLoading" :is-full-window="false" bg="blue" />
                  <pxs-proposal-preview
                    :is-full-screen="isFullScreen"
                    :proposal-id="itemId"
                    :proposal-status="status"
                    :signed-manualy="signedManualy"
                  >
                    <template #sidebar>
                      <div class="border-top pt-4 pb-4">
                        <div v-if="signedPdfFile" class="d-flex align-items-center">
                          <px-btn
                            color="primary"
                            extended-classes="btn-flex w-100 text-center justify-content-center"
                            :in-process="removePdfFileInProcess"
                            :disabled="removePdfFileInProcess"
                            @click.native="downloadContract"
                          >
                            <i class="bi bi-download me-2" />
                            Download Contract
                          </px-btn>
                          <div v-if="signedManualy" class="d-flex ms-2">
                            <px-btn
                              color="light-danger"
                              extended-classes="btn-icon w-50px h-50px p-0 ms-1"
                              @click.native="removeContract"
                            >
                              <i class="bi bi-trash" />
                            </px-btn>
                          </div>
                        </div>
                        <div v-else class="d-flex align-items-center">
                          <div v-if="signedManualy" class="w-100">
                            <div
                              v-if="!contract"
                              class="btn btn-primary btn-flex position-relative w-100 text-center justify-content-center overflow-hidden"
                              role="button"
                            >
                              <i class="bi bi-file-pdf me-2" />
                              Select PDF File
                              <input
                                class="position-absolute top-0 bottom-0 left-0 right-0 opacity-0 cursor-pointer"
                                type="file"
                                accept="application/pdf"
                                @change="(evt) => chooseContract(evt)"
                              />
                            </div>
                            <px-btn
                              v-else
                              color="primary"
                              extended-classes="btn-flex w-100 text-center justify-content-center"
                              :in-process="uploadPdfFileInProcess"
                              :disabled="uploadPdfFileInProcess"
                              @click.native="uploadContract"
                            >
                              <i class="bi bi-upload me-2" />
                              Upload PDF File
                              <!--                            {{ contract.name }}-->
                            </px-btn>
                          </div>
                          <div v-else class="w-100">
                            <px-btn
                              color="primary"
                              extended-classes="btn-flex w-100 text-center justify-content-center"
                              :in-process="isSignedManuallyInProcess"
                              :disabled="isSignedManuallyInProcess"
                              @click.native="onSignManuallyProposal"
                            >
                              <i class="bi bi-pencil me-2" />
                              Sign Manually
                            </px-btn>
                          </div>

                          <div v-if="contract" class="d-flex ms-2">
                            <px-btn
                              color="light-danger"
                              extended-classes="btn-icon w-50px h-50px p-0 ms-1"
                              @click.native="deleteUpload"
                            >
                              <i class="bi bi-trash" />
                            </px-btn>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #before-header>
                      <div v-show="isFullScreen" class="card-header justify-content-end align-items-center py-3">
                        <div class="d-flex align-items-center">
                          <span
                            v-if="isCurrent"
                            class="badge badge-primary fw-medium text-uppercase text-ls fs-10 px-3 py-2 me-4"
                          >
                            {{ signedManualy ? 'Signed Manually' : status }}
                          </span>
                          <span v-else class="badge badge-danger fw-medium text-uppercase text-ls fs-10 px-3 py-2 me-4">
                            Old Version
                          </span>
                          <div v-if="historyItems.length" class="min-w-200px me-4">
                            <px-select
                              v-model="historyItemsVal"
                              placeholder="Select a version"
                              :items="historyItems"
                              track-by="id"
                              :text-by="['attributes.number', ' ', '|', ' ', 'v', 'attributes.version']"
                              :minimum-results-for-search="Infinity"
                              select2-id="select2_history_items_ids"
                              @input="() => goToHistory(historyItemsVal)"
                            />
                          </div>
                          <div
                            class="btn btn-icon btn-light btn-active-light-primary me-4"
                            data-fc-fullscreen-toggler
                            @click="toggleFullScreen"
                          >
                            <i class="bi bi-fullscreen-exit" />
                          </div>
                          <div class="position-relative">
                            <div
                              v-if="isContentLoading"
                              class="position-absolute top-0 bottom-0 start-0 end-0 z-4 d-flex align-items-center justify-content-center bg-primary rounded"
                            >
                              <span class="spinner-border spinner-border-sm" />
                            </div>
                            <px-dropdown
                              v-if="isCurrent"
                              title="Actions"
                              btn-classes="btn btn-sm btn-primary dropdown-toggle"
                              :is-icon-hidden="true"
                              :items="[
                                {
                                  title: 'Create new Version',
                                  disabled: createNewProposalBasedOnCurrentInProcess,
                                  inProcess: createNewProposalBasedOnCurrentInProcess,
                                  action: () => createNewProposalBasedOnCurrent(itemId),
                                },
                                { title: 'Share', action: openModal },
                                {
                                  title: 'Generate PDF',
                                  disabled: isPdfInProcess,
                                  inProcess: isPdfInProcess,
                                  action: () => makeAndDownloadPdf(pdfFile),
                                },
                                {
                                  title: 'Edit',
                                  disabled: isSentToSign,
                                  action: () => createNewProposalBasedOnCurrent(itemId),
                                },
                                {
                                  title: isSentToSign ? 'Resend for Sign' : 'Send for Sign',
                                  disabled: subTotal <= 0 || signedPdfFile,
                                  inProcess: isPdfAndSendForSignInProcess,
                                  action: sendForSign,
                                },
                                { title: 'Print', disabled: true, action: printContract },
                                { title: 'Download', disabled: true, action: () => downloadFile(pdfFile) },
                              ]"
                            />

                            <px-dropdown
                              v-else
                              title="Actions"
                              btn-classes="btn btn-sm btn-primary dropdown-toggle"
                              :is-icon-hidden="true"
                              :items="[
                                {
                                  title: 'Create new Version',
                                  disabled: createNewProposalBasedOnCurrentInProcess,
                                  inProcess: createNewProposalBasedOnCurrentInProcess,
                                  action: () => createNewProposalBasedOnCurrent(itemId),
                                },
                                { title: 'PDF', action: () => downloadFile(pdfFile) },
                                { title: 'Print', disabled: true, action: printContract },
                                { title: 'Download', disabled: true, action: () => downloadFile(pdfFile) },
                              ]"
                            />
                          </div>
                        </div>
                        <!--                        <div-->
                        <!--                          class="btn btn-icon btn-light btn-active-light-primary"-->
                        <!--                          data-fc-fullscreen-toggler-->
                        <!--                          @click="toggleFullScreen"-->
                        <!--                        >-->
                        <!--                          <i class="bi bi-fullscreen-exit" />-->
                        <!--                        </div>-->
                      </div>
                    </template>
                  </pxs-proposal-preview>

                  <div class="row">
                    <div class="col-lg-3" />
                    <div class="col-lg-9">
                      <div class="d-flex flex-stack mt-8 pt-8 border-top">
                        <div class="ms-auto">
                          <px-btn
                            color="success"
                            extended-classes="me-2"
                            :in-process="updateInProcess"
                            :disabled="updateInProcess"
                            @click.native="onUpdate"
                          >
                            Update
                          </px-btn>
                        </div>
                        <!--end::Wrapper-->
                      </div>
                      <!--end::Actions-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeModal">
      <template #title>
        <h3>Share</h3>
      </template>
      <template #content>
        <pxm-share-compact
          v-if="signedPdfFile"
          :item-id="itemId"
          :code="subject.code"
          :secret="subject.secret"
          @onClose="closeModal"
        />
        <pxm-share v-else :item-id="itemId" :predefined-subject="subject" @onClose="closeModal" />
      </template>
    </px-new-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import PxsProposalPreview from '@/sections/PxsProposalPreview';

import PxmShare from '@/modals/PxmShare';
import PxmShareCompact from '@/modals/PxmShareCompact.vue';

export default {
  components: { PxmShareCompact, PxmShare, PxsProposalPreview },
  data() {
    return {
      itemId: this.$router.history.current.params.id,
      current: this.$router.history.current.name,
      historyItems: [],
      historyItemsVal: null,
      isShareModalOpened: false,
      state: null,
      signedManualy: false,
      signedPdfFile: null,
      status: null,
      number: null,
      version: null,
      projectName: null,
      scopeType: null,
      subject: null,
      subjectId: null,
      subTotal: null,
      manager: null,
      pdfFile: null,
      isPdfReady: false,
      isPdfInProcess: false,
      isPdfAndSendForSignInProcess: false,
      isCurrent: false,
      isShared: false,
      isSentToSign: false,
      isContentLoading: false,
      isSignedManuallyInProcess: false,
      users: [],

      updateInProcess: false,

      createNewProposalBasedOnCurrentInProcess: false,

      uploadPdfFileInProcess: false,
      removePdfFileInProcess: false,
      contract: null,

      isFullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',

      proposalsMessage: 'proposals/message',
      showEquipments: 'proposals/showEquipments',
      showInclusions: 'proposals/showInclusions',
      showIntroduction: 'proposals/showIntroduction',
      showPaymentDetails: 'proposals/showPaymentDetails',
      showPricing: 'proposals/showPricing',
      showSpecialNotes: 'proposals/showSpecialNotes',
      showSpecifications: 'proposals/showSpecifications',
      showTerms: 'proposals/showTerms',
    }),
  },
  async mounted() {
    await this.init(this.itemId);
    await this.requestAttachedSpecifications();
  },
  unmounted() {
    this.mutationCurrentProposalId(null);
  },
  methods: {
    ...mapActions({
      single: 'proposals/single',
      actionUpdate: 'proposals/update',
      actionGetCurrentState: 'proposals/getCurrentState',
      actionUploadSignedPdfFile: 'proposals/uploadSignedPdfFile',
      actionRemoveSignedPdfFile: 'proposals/removeSignedPdfFile',
      getAllByParentId: 'proposals/getAllByParentId',
      actionCreateNewVersion: 'proposals/createNewVersion',
      actionProposalWizardMakePdf: 'proposalWizard/makePdf',
      actionProposalWizardSendForSign: 'proposalWizard/sendForSign',
      requestAttachedSpecifications: 'attachedSpecifications/index',
    }),
    ...mapMutations({
      mutationCurrentProposalId: 'proposals/SET_CURRENT_PROPOSAL_ID',

      mutationShowEquipments: 'proposals/SET_SHOW_EQUIPMENTS',
      mutationShowInclusions: 'proposals/SET_SHOW_INCLUSIONS',
      mutationShowIntroduction: 'proposals/SET_SHOW_INTRODUCTION',
      mutationShowPaymentDetails: 'proposals/SET_SHOW_PAYMENT_DETAILS',
      mutationShowPricing: 'proposals/SET_SHOW_PRICING',
      mutationShowPlantings: 'proposals/SET_SHOW_PLANTINGS',
      mutationShowSpecialNotes: 'proposals/SET_SHOW_SPECIAL_NOTES',
      mutationShowSpecifications: 'proposals/SET_SHOW_SPECIFICATIONS',
      mutationShowTerms: 'proposals/SET_SHOW_TERMS',
    }),
    toggleFullScreen() {
      this.isFullScreen = !this.isFullScreen;
    },
    async init(id) {
      this.isContentLoading = true;

      const fieldsArr = [
        'current',
        'number',
        'parent_id',
        'pdf_file',
        'scope_type',
        'sent_to_sign',
        'shared',
        'show_equipments',
        'show_inclusions',
        'show_introduction',
        'show_payment_details',
        'show_plantings',
        'show_pricing',
        'show_special_notes',
        'show_specifications',
        'show_terms',
        'signed_manualy',
        'signed_pdf_file',
        'state',
        'status',
        'subject',
        'sub_total',
        'users',
        'version',
      ];
      const single = await this.single({
        id,
        params: {
          fields: this.arrayToString(fieldsArr),
        },
      });
      const singleAttributes = single?.data?.data?.attributes;
      const historyItems = await this.getAllByParentId({
        per: 10000,
        parent_id: singleAttributes.parent_id || single.data.data.id,
        id: singleAttributes.parent_id || single.data.data.id,
        scope_type: singleAttributes.scope_type,
      });

      this.historyItems = historyItems.data;
      this.historyItemsVal = single.data.data.id;
      this.state = singleAttributes.state || null;
      this.status = singleAttributes.status || null;
      this.number = singleAttributes.number || null;
      this.version = singleAttributes.version || '1';
      this.signedPdfFile = singleAttributes.signed_pdf_file || null;
      this.signedManualy = singleAttributes.signed_manualy || false;
      this.scopeType = singleAttributes.scope_type || null;
      this.projectName = singleAttributes.subject?.data?.attributes?.address || null;
      this.subject = singleAttributes.subject?.data?.attributes || {};
      this.subjectId = singleAttributes.subject?.data?.id || null;
      this.subTotal = singleAttributes.sub_total || 0;
      this.manager = {
        avatar: singleAttributes.subject?.data?.attributes?.manager?.data?.attributes.avatar || null,
        full_name: `${singleAttributes.subject?.data?.attributes?.manager?.data?.attributes.first_name || ''} ${
          singleAttributes.subject?.data?.attributes?.manager?.data?.attributes.last_name || ''
        }`,
        initials: `${singleAttributes.subject?.data?.attributes?.manager?.data?.attributes.first_name.charAt(0) || ''}${
          singleAttributes.subject?.data?.attributes?.manager?.data?.attributes.last_name.charAt(0) || ''
        }`,
      };
      this.pdfFile = singleAttributes.pdf_file || null;
      this.isCurrent = singleAttributes.current || null;
      this.isShared = singleAttributes.shared || false;
      this.isSentToSign = singleAttributes.sent_to_sign || false;
      this.users = singleAttributes.users?.data || [];

      this.mutationCurrentProposalId(id);
      this.isContentLoading = false;
    },
    printContract() {
      window.print();
    },
    async goToHistory(id) {
      this.itemId = id;

      await this.$router.push({ name: 'proposal-preview', params: { id } });
      await this.init(id);
    },
    goToWizard(id) {
      this.$router.push({ name: 'proposal-wizard', params: { id } });
    },
    async createNewProposalBasedOnCurrent(id) {
      this.createNewProposalBasedOnCurrentInProcess = true;

      const newProposalVersion = await this.actionCreateNewVersion(id);
      const newProposalVersionId = newProposalVersion.data.data.id;

      this.createNewProposalBasedOnCurrentInProcess = false;

      this.goToWizard(newProposalVersionId);
    },
    onExit() {
      this.$router.push({ name: 'single-project', params: { id: this.subjectId } });
    },
    async makePdf() {
      this.isPdfInProcess = true;

      const newPdf = await this.actionProposalWizardMakePdf(this.itemId);

      await this.v_toast(this.message?.status, 'Sent successfully!', () => {
        this.isPdfInProcess = false;
      });

      return newPdf;
    },
    async makeAndDownloadPdf() {
      const newPdf = await this.makePdf();

      await this.downloadFile(newPdf.data.pdf_file);
    },
    async sendForSign() {
      this.isPdfAndSendForSignInProcess = true;

      try {
        await this.actionProposalWizardMakePdf(this.itemId);
        await this.actionProposalWizardSendForSign(this.itemId);
        await this.v_toast(this.message?.status, 'Sent successfully!', () => {
          this.isPdfAndSendForSignInProcess = false;

          this.mutationShowEquipments(true);
          this.mutationShowInclusions(true);
          this.mutationShowIntroduction(true);
          this.mutationShowPaymentDetails(true);
          this.mutationShowPricing(true);
          this.mutationShowPlantings(true);
          this.mutationShowSpecialNotes(true);
          this.mutationShowSpecifications(true);
          this.mutationShowTerms(true);

          this.status = 'Sent to Sign';
          this.isPdfReady = true;
          this.isSentToSign = true;
        });
      } catch {
        this.isPdfAndSendForSignInProcess = false;
      }
    },
    async onSignManuallyProposal() {
      this.isSignedManuallyInProcess = true;

      const response = await this.actionUpdate({ id: this.itemId, signed_manualy: true, status: 'Signed' });

      this.isSignedManuallyInProcess = false;

      if (response.status === 422) {
        this.v_toast(response.status, response.data.errors.status, () => {}, {
          timer: 5000,
        });
      } else {
        this.signedManualy = true;
        this.status = 'Signed';
      }
    },
    async downloadFile(url) {
      this.downloadPdfFromTab(url, 'proposal');
    },
    async chooseContract(data) {
      this.contract = {
        name: data.target.files[0].name,
        file: data.target.files[0],
      };
    },
    async uploadContract() {
      const formData = new FormData();
      formData.append('proposal_scope[signed_pdf_file]', this.contract.file);

      this.uploadPdfFileInProcess = true;

      const contract = await this.actionUploadSignedPdfFile({
        id: this.itemId,
        signed_pdf_file: formData,
      });

      this.uploadPdfFileInProcess = false;
      this.signedPdfFile = contract.data.signed_pdf_file;
      this.signedManualy = contract.data.signed_manualy;
      // this.status = 'Signed';

      this.deleteUpload();
    },
    deleteUpload() {
      this.contract = null;
    },
    downloadContract() {
      this.downloadPdfFromTab(this.signedPdfFile, `${this.number} v${this.version}`);
    },
    async removeContract() {
      this.removePdfFileInProcess = true;

      try {
        await this.actionRemoveSignedPdfFile(this.itemId);

        this.signedPdfFile = null;
      } catch {
        console.log('Some error!');
      }

      this.removePdfFileInProcess = false;
    },
    async onUpdate() {
      this.updateInProcess = true;

      await this.actionUpdate({
        id: this.itemId,
        show_equipments: this.showEquipments,
        show_inclusions: this.showInclusions,
        show_introduction: this.showIntroduction,
        show_payment_details: this.showPaymentDetails,
        show_pricing: this.showPricing,
        show_special_notes: this.showSpecialNotes,
        show_specifications: this.showSpecifications,
        show_terms: this.showTerms,
      });

      this.updateInProcess = false;

      await this.v_toast(this.proposalsMessage?.status, 'Updated successfully!');
    },
    openModal() {
      this.isShareModalOpened = true;
    },
    closeModal() {
      this.isShareModalOpened = false;
    },
  },
};
</script>

<style lang="scss">
#kt_body {
  background-image: url('/assets/media/header-bg.png');
}
</style>
